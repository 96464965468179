import {Salary, SalaryItemTypeEnum, useSendSalariesMutation} from "../generated/graphql";
import React, {useCallback, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    Grid, InputLabel,
    MenuItem,
    Select, TextField,
    Typography
} from "@mui/material";
import theme from "../theme/Theme";
import {format} from "date-fns";
import {find, reduce} from "lodash";
import NotificationPopup from "../common/NotificationPopup";


export default ({salaryGroups}) =>
{
    const [open, setOpen] = useState(false);
    const [selectedSalaryGroup, setSelectedSalaryGroup] = useState<any>();
    const [selectedSalaryIds, setSelectedSalaryIds] = useState<any>([]);
    const [subject, setSubject] = useState<string>('');
    const [body, setBody] = useState<string>('');
    const [sendSalaries] = useSendSalariesMutation();
    const onSend = useCallback(async () => {
        try {
            await sendSalaries({
                variables: {
                    subject: subject,
                    body: body,
                    salaryIds: selectedSalaryIds,
                },
                refetchQueries: ["getSalaryGroups"]
            });
            NotificationPopup.success(`寄送成功`);
            setOpen(false);
        }
        catch (e: any) {
            NotificationPopup.error(
                `發生問題：${e.message}`
            );
            console.error(e);
        }
    }, [subject, body, selectedSalaryIds])
    return <div>
        <Dialog open={open} fullWidth={true} maxWidth={"sm"}>
            <DialogTitle style={{backgroundColor: theme.palette.primary.dark}}>
                <Typography variant="h3" align="center">
                    薪資單寄送
                </Typography>
            </DialogTitle>
            <DialogContent style={{marginTop: 20}}>

                <FormControl style={{width: "100%", marginTop: 10}} required={true}>
                    <InputLabel>薪資發放名稱</InputLabel>
                    <Select label="薪資發放名稱" onChange={(event: any) =>
                    {
                        const salaryGroup = find(salaryGroups, (salaryGroup) => salaryGroup.id == event.target.value)
                        setSelectedSalaryGroup(salaryGroup);
                        setSelectedSalaryIds(salaryGroup.salaries.map((salary) => salary.id));
                    }}>
                        {salaryGroups.map((salaryGroup) =>
                        {
                            return (
                                <MenuItem value={salaryGroup.id} key={salaryGroup.id}>
                                    {salaryGroup.title}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl style={{width: "100%", marginTop: 10}}  required={true}>
                    <InputLabel>發放員工</InputLabel>
                    <Select label="發放員工" multiple value={selectedSalaryIds} onChange={(event: any) =>
                    {
                        setSelectedSalaryIds(event.target.value);
                    }}>
                        {selectedSalaryGroup?.salaries?.map((salary) =>
                        {
                            return (
                                <MenuItem value={salary.id} key={salary.id}>
                                    {salary.employee.code} {salary.employee.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <TextField
                    label={"標題"}
                    style={{width: "100%", marginTop: 10}}
                    onChange={({target})=>setSubject(target.value)}>

                </TextField>
                <TextField
                    label={"內文"}
                    style={{width: "100%", marginTop: 10}}
                    onChange={({target})=>setBody(target.value)}>

                </TextField>
            </DialogContent>
            <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={async () =>
                    {
                        await onSend();
                    }}
                >
                    寄送
                </Button>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() =>
                    {
                        setOpen(false);
                    }}
                >
                    取消
                </Button>
            </DialogActions>
        </Dialog>
        <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpen(true)}
        >
            薪資單寄送
        </Button>
    </div>
}