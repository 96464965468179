import Page from "../common/Page";
import {Box, Button, Container, Grid, MenuItem, Select} from "@mui/material";
import {DataGridPro, GridColDef, GridSortModel, GridValueGetterFullParams} from "@mui/x-data-grid-pro";
import {checkAlert, DataGridLocale} from "../common/Utils";
import DefaultQueryHandler from "../common/DefaultQueryHandler";
import {
    Authorization,
    Company,
    SalaryItem,
    useGetEmployeesQuery,
    useUpdateEmployeeMutation
} from "../generated/graphql";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {format} from "date-fns";
import {filter, find, reduce} from "lodash";
import {useUser} from "../auth/Auth";
import {InputType} from "../common/FormRenderer";
import UpdatePopupButton from "../component/UpdatePopupButton";
import {useNavigate} from "react-router";
import EmployeeDetailButton from "./EmployeeDetailButton";
import UpdateContractorButton from "../contractor/UpdateContractorButton";
import ConfirmButton from "../component/ConfirmButton";
import {CompanyContext} from "../routes";
import DownloadButton from "../component/DownloadButton";
import UploadFile from "../common/UploadFile";
import NotificationPopup from "../common/NotificationPopup";
import {EmployStateMap, IncomeTypeMap, WorkTypeMap} from "../common/Constant";


export default () => {
    const [sortModel, setSortModel] = useState<GridSortModel>([{field:"code",sort:"asc"}]);
    const {loading, data, error} = useGetEmployeesQuery();
    const [filterEmployees, setFilterEmployees] = useState<any>([]);
    const [updateEmployee] = useUpdateEmployeeMutation();
    const me = useUser();
    const navigate = useNavigate();
    const company = useContext(CompanyContext);
    checkAlert(company, Authorization.Personnel);
    const onDisabled = useCallback(
        async (id) => {
            try {
                await updateEmployee({
                    variables: {employee: {disabled: true}, id: id},
                    refetchQueries: ["getEmployees"],
                });
            }
            catch (e: any) {

            }
        }, [updateEmployee]);
    useEffect(() => {
        if (company) {
            setFilterEmployees(filter(data?.employees, employee => employee?.company?.id == company.id))
        }
    }, [data, company]);
    const columns: GridColDef[] = [
        {field: "code", headerName: "員工編號", width: 120},
        {field: "name", headerName: "姓名", width: 120},
        {field: "arrivalDate", headerName: "到職日", width: 120, valueGetter: params =>
                (params as any).value ? format( new Date((params as any).value), "yyyy-MM-dd") : "--"},
        {field: "leaveDate", headerName: "離職日", width: 120, valueGetter: params =>
                (params as any).value ? format(new Date((params as any).value), "yyyy-MM-dd") : "--"},
        {field: "state", headerName: "在職狀態", width: 120, valueGetter: params => EmployStateMap[(params as any).value]},
        {field: "type", headerName: "計薪方式", width: 120, valueGetter: params => WorkTypeMap[(params as any).value]},
        {field: "salaryItems", headerName: "薪資總計", width: 120, valueGetter: params =>
                reduce(((params as any).value as [SalaryItem]),(acc, salaryItem)=>acc+(salaryItem.amount ?? 0), 0)},
        {field: "updatedAt", headerName: "更新日期", width: 120, valueGetter: params =>
                format((params as any).value ? new Date((params as any).value) : new Date(), "yyyy-MM-dd")},
        {
            field: "action", headerName: "動作", width: 150,
            renderCell: (params) => {
                return <>
                    <EmployeeDetailButton
                        employee={params.row}
                    />&nbsp;
                    <ConfirmButton onConfirm={async () => {
                        await onDisabled(params.row.id);
                    }}>刪除</ConfirmButton>
                </>
            }
        }
    ];
    return (
        <Page title={"employee page"}>
            <Container maxWidth={false} style={{padding: "30px"}}>
                <Grid container spacing={2} direction={"row"} alignItems={"center"}>
                    <Grid item>
                        <EmployeeDetailButton/>
                    </Grid>
                    <Grid item>
                        <UploadFile
                            uploadTitle={"匯入員工資料"}
                            uploadPath={`/employee/upload?type=employee&companyId=${company?.id}`}
                            onSuccess={(res) =>
                            {
                                NotificationPopup.success(`匯入成功`);
                                window.location.reload();
                            }}></UploadFile>
                    </Grid>
                    <Grid item>
                        <DownloadButton
                            title={"匯出員工資料"}
                            path={`/employee/export?type=employee&companyId=${company?.id}`}
                            filename={"員工資料表"}
                            companyId={company?.id}/>
                    </Grid>
                    <Grid item>
                        <DownloadButton
                            color={"secondary"}
                            title={"員工資料匯入範例"}
                            path={`/employee/export?type=employee&companyId=${company?.id}&sample=1`}
                            filename={"員工資料匯入範例"}
                            companyId={company?.id}/>
                    </Grid>
                    <Grid item>
                        <UploadFile
                            uploadTitle={"匯入眷屬資料"}
                            uploadPath={`/employee/upload?type=dependent&companyId=${company?.id}`}
                            onSuccess={(res) =>
                            {
                                NotificationPopup.success(`匯入成功`);
                                window.location.reload();
                            }}></UploadFile>
                    </Grid>
                    <Grid item>
                        <DownloadButton
                            title={"匯出眷屬資料"}
                            path={`/employee/export?type=dependent&companyId=${company?.id}`}
                            filename={"眷屬資料表"}
                            companyId={company?.id}/>
                    </Grid>
                    <Grid item>
                        <DownloadButton
                            color={"secondary"}
                            title={"眷屬資料匯入範例"}
                            path={`/employee/export?type=dependent&companyId=${company?.id}&sample=1`}
                            filename={"眷屬資料匯入範例"}
                            companyId={company?.id}/>
                    </Grid>
                    <Grid item>
                        <UploadFile
                            uploadTitle={"匯入薪資"}
                            uploadPath={`/employee/upload?type=salaryItem&companyId=${company?.id}`}
                            onSuccess={(res) =>
                            {
                                NotificationPopup.success(`匯入成功`);
                                window.location.reload();
                            }}></UploadFile>
                    </Grid>
                    <Grid item>
                        <DownloadButton
                            title={"匯出薪資"}
                            path={`/employee/export?type=salaryItem&companyId=${company?.id}`}
                            filename={"薪資列表"}
                            companyId={company?.id}/>
                    </Grid>
                    <Grid item>
                        <DownloadButton
                            color={"secondary"}
                            title={"薪資匯入範例"}
                            path={`/employee/export?type=salaryItem&companyId=${company?.id}&sample=1`}
                            filename={"薪資匯入範例"}
                            companyId={company?.id}/>
                    </Grid>
                </Grid>
                <DefaultQueryHandler error={error} loading={loading}>
                    <Box
                        width={"100%"}
                        style={{marginTop: "30px", height: "100vh"}}
                    >
                        <DataGridPro
                            localeText={DataGridLocale}
                            autoHeight={true}
                            loading={loading}
                            rows={filterEmployees || []}
                            columns={columns}
                            sortModel={sortModel}
                            onSortModelChange={(sortModel)=> {
                                setSortModel(sortModel)
                            }}
                        />
                    </Box>
                </DefaultQueryHandler>
            </Container>

        </Page>
    );
}
