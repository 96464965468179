import React, {useCallback, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, Grid, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {
    IncomeTypeEnum,
    LaborContract,
    LaborContractStatusEnum,
    Maybe,
    TaxClassificationEnum,
    useDuplicateLaborContractMutation,
    useGetLaborContractQuery, useRemoveLaborContractMutation,
    useUpdateLaborContractMutation
} from "../generated/graphql";
import {LaborContractStatusMap} from "../common/Constant";
import {useNavigate, useParams} from "react-router-dom";
import {isEmpty, omit} from "lodash";
import NotificationPopup from "../common/NotificationPopup";
import ConfirmButton from "../component/ConfirmButton";
import {PDFDownloadLink, PDFViewer} from "@react-pdf/renderer";
import Pdf from "./pdf/Pdf";
import LaborContractView from "./LaborContractView";
import {format} from "date-fns";
import theme from "../theme/Theme";
import {calculateContractTotals} from "../common/Utils";
import InsuranceButton from "./InsuranceButton";
import TaxButton from "./TaxButton";


const useStyles = makeStyles((theme) => ({
    tableContainer: {
        borderWidth: 1,
        borderColor: "black",
        borderStyle: "solid",
    },
    tableRow: {
        border: "1px solid"
    },
    tableCell: {
        border: "1px solid"
    },
    divider: {
        // Theme Color, or use css color in quote
        background: "white",
    },
}))

const getMoneyTextfield = () => {
    return (<TextField style={{width: 100, marginLeft: 5, marginRight: 5}}
                       inputProps={{style: {padding: 0, textAlign: 'center'}}} type="number"/>)
}

export const EditTextField = (props) => {
    return <TextField
        {...props}
        variant={"outlined"}
        style={{width: "100%", marginTop: 10}}
        value={props.value||""}
        onChange={({target}) => {
            if (props.setter) {
                props.setter(target.value)
            }
        }}
    />;
}
export const StyleTextField = (props) => {
    return <TextField
        variant={"outlined"}
        style={{width: "100%", marginTop: 10}}
        value={props.value}
        {...props}
    />;
}
export const getPublishEnum = (lc: LaborContract) => {
    if (lc == null)
        return null;
    let contractorFieldsList = [
        "contractorName",
        "contractorAddress",
        "contractorEmail",
        "contractorPhone",
        "contractorIdNumber",
        "contractorTaxClassification",
        "contractorContactAddress",
        "contractorBankCode",
        "contractorBankAccountNumber",
        // "contractorNoInsurance",
        "contractorBankAccountName",
        "idFrontFile",
        "passbookFile",
    ]
    if (lc.contractorTaxClassification != TaxClassificationEnum.Citizen) {
        contractorFieldsList = [...contractorFieldsList, "contractorNationality"]
    }
    else {
        contractorFieldsList = [...contractorFieldsList, "idBackFile"]
    }
    const laborContractFieldsList = [
        "name",
        "date",
        "payDate",
        "contractContent",
        "incomeType",
        "amount",
        "paymentMethod",
        "startDate",
        "endDate",
        "operator",
        "operatorPhone",
    ]
    let finished = true;
    if (lc.editable) {
        laborContractFieldsList.map((key) => {
            if (!lc[key]) {
                finished = false;
            }
        })
        return finished ? PublicMessageEnum.EditableFinish : PublicMessageEnum.EditableNotFinish;
    }
    else {
        [...laborContractFieldsList, ...contractorFieldsList].map((key) => {
            if (!lc[key]) {
                console.log(key);
                finished = false;
            }
        })
        return finished ? PublicMessageEnum.NotEditableFinish : PublicMessageEnum.NotEditableNotFinish;
    }
}

export enum PublicMessageEnum {
    EditableNotFinish = "請確認所有＊欄位都已填寫，才可發佈。",
    EditableFinish = "確定要將勞報單發佈給廠商了嗎？請確認除所得人基本資訊以外之欄位都已填寫完整正確。",
    NotEditableFinish = "確定要將勞報單發佈給廠商確認了嗎？請確認欄位都已填寫完整正確。",
    NotEditableNotFinish = "請確認所有＊欄位都已填寫，才可發佈。",
}

export const AlertDialogButton = ({children, message}) => {
    const [alert, setAlert] = useState(false);
    return <><Dialog open={alert} fullWidth={true}>
        <DialogContent>
            {message}
        </DialogContent>
        <DialogActions style={{justifyContent: "center"}}>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                    setAlert(false);
                }}
            >
                確認
            </Button>
        </DialogActions>
    </Dialog>
        <Button
            variant={"contained"}
            color={"info"}
            onClick={() => setAlert(true)}>
            {children}
        </Button>
    </>
}

export default () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const laborContractQuery = useGetLaborContractQuery({variables: {id: parseInt(id as string)}});
    const [updateLaborContract] = useUpdateLaborContractMutation();
    const [editLaborContract, setEditLaborContract] = useState<Maybe<any>>();
    const [preview, setPreview] = useState(false);
    const [duplicateLaborContract] = useDuplicateLaborContractMutation();
    const calculatedAmount = calculateContractTotals(
        editLaborContract?.amount,
        editLaborContract?.contractorTaxClassification as TaxClassificationEnum,
        editLaborContract?.incomeType as IncomeTypeEnum,
        editLaborContract?.contractorNoInsurance,
    )
    const [removeLaborContract] = useRemoveLaborContractMutation();
    const [ready, setReady] = useState(false);
    const onRemove = async (id) =>
    {
        await removeLaborContract({
            variables: {
                id: parseInt(id),
            },
            refetchQueries: ["getLaborContracts"]
        });
        NotificationPopup.success(`刪除成功`);
        navigate(`/laborContract`);
    }

    const onUpdate = async (laborContract) => {
        if (laborContract.amount) {
            laborContract.amount = parseInt(laborContract.amount);
        }
        if (laborContract.startDate && laborContract.endDate) {
            if (laborContract.startDate > laborContract.endDate) {
                NotificationPopup.error(`勞務期間（迄）不得早於勞務期間（起）`);
                return;
            }
        }
        try {
            await updateLaborContract({
                variables: {
                    laborContract: omit(laborContract,
                        ["createdAt", "updatedAt", "id", "company", "idFrontFile", "signatureFile", "idBackFile", "passbookFile", "uuid"]),
                    id: editLaborContract.id,
                },
                refetchQueries: ["getLaborContract", "getLaborContracts"],
            });
            NotificationPopup.success(`更新成功`);
        } catch (e: any) {
            NotificationPopup.error(`發生問題: ${e.message}`);
            console.error(e);
        }
    };


    const onChange = useCallback((fieldName) => {
        return (value) => {
            setEditLaborContract(editLaborContract => ({...editLaborContract, [fieldName]: value}));
        }
    }, [setEditLaborContract]);

    const onDuplicate = async (id) => {
        try {
            const {data} = await duplicateLaborContract({
                variables: {
                    id
                },
                refetchQueries: ["getLaborContracts"],
            })
            navigate(`/laborContract/${data?.duplicateLaborContract.id}`)
        } catch (e: any) {
            NotificationPopup.error(`發生問題: ${e.message}`);
        }
    }
    const isFinished = editLaborContract?.status == LaborContractStatusEnum.Finished

    useEffect(() => {
        const laborContract = laborContractQuery?.data?.laborContract
        if (laborContract) {
            setEditLaborContract({
                ...laborContract,
            })
        }

    }, [laborContractQuery])

    useEffect(()=>{
        setTimeout(()=>{
            setReady(true);
            // console.log("pdf ready");
        }, 2000);
    },[])
    const publishEnum = getPublishEnum(editLaborContract);

    if (laborContractQuery.loading || isEmpty(editLaborContract)) {
        return <></>
    }
    const pdf = <Pdf laborContract={laborContractQuery.data?.laborContract as LaborContract}/>;

    return (<>
            {preview &&
            <Grid container direction={"column"} style={{width: "100%", height: "100%"}}>
                <div>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => setPreview(false)}
                    >
                        關閉
                    </Button>
                </div>

                <PDFViewer width={1000} height={1200}>
                    {pdf}
                </PDFViewer>
            </Grid>}
            <Grid container style={{margin: 30, width: "90%"}} spacing={2}>
                <Grid container xs={12} spacing={2} style={{marginBottom: 10, marginLeft: 0}}>
                    <Grid item>
                        {isFinished && ready ? <Button
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => setPreview(true)}
                        >
                            預覽
                        </Button> : <AlertDialogButton message="送財務後才能執行下載或預覽。">
                            預覽
                        </AlertDialogButton>}
                    </Grid>
                    <Grid item>
                        {laborContractQuery.data &&
                        (isFinished && ready ? <PDFDownloadLink
                            style={{textDecoration: "none"}}
                            fileName={`${editLaborContract?.name}.pdf`}
                            document={pdf}>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                            >
                                下載
                            </Button>
                        </PDFDownloadLink> : <AlertDialogButton
                            message="送財務後才能執行下載或預覽。">
                            下載
                        </AlertDialogButton>)}
                    </Grid>
                    <Grid item>
                        <ConfirmButton
                            onConfirm={async () => {
                                await onRemove(id);
                            }}>刪除</ConfirmButton>
                    </Grid>

                    {
                        editLaborContract?.status == LaborContractStatusEnum.Draft &&
                        <Grid item>
                            {(publishEnum == PublicMessageEnum.EditableFinish ||
                                publishEnum == PublicMessageEnum.NotEditableFinish) ? <ConfirmButton
                                onConfirm={async () => {
                                    await onUpdate({...editLaborContract, status: LaborContractStatusEnum.Unsigned});
                                }}
                                message={publishEnum}
                                buttonProps={{color: "primary"}}
                            >
                                發佈
                            </ConfirmButton> : <AlertDialogButton message={publishEnum}>發佈</AlertDialogButton>}
                        </Grid>
                    }
                    {
                        editLaborContract?.status == LaborContractStatusEnum.Signed &&
                        <Grid item>
                            <ConfirmButton onConfirm={() => {
                                onUpdate({status: LaborContractStatusEnum.Finished})
                            }} message={
                                `請確認勞務期間為${format(new Date(editLaborContract.startDate), "yyyy-MM-dd")}`+
                                `~${format(new Date(editLaborContract.endDate), "yyyy-MM-dd")}`+
                                `，支付日期為${format(new Date(editLaborContract.payDate), "yyyy-MM-dd")}`+
                                `，若不正確請回頁面修改。結案後不可修改，確定結案嗎？`}
                                           buttonProps={{color: "primary"}}>
                                送財務
                            </ConfirmButton>
                        </Grid>
                    }
                    {
                        (editLaborContract?.status == LaborContractStatusEnum.Signed ||
                        editLaborContract?.status == LaborContractStatusEnum.Unsigned) &&
                        <Grid item>
                            <ConfirmButton buttonProps={{color: "success"}} onConfirm={() => {
                                onUpdate({status: LaborContractStatusEnum.Draft, signatureFileId: null})
                            }} message={`異動後要重新發佈並請廠商重新簽名，確定要異動嗎？`}>
                                異動
                            </ConfirmButton>
                        </Grid>
                    }
                    {
                        editLaborContract.status == LaborContractStatusEnum.Unsigned &&
                        <Grid item>
                            <div>
                                <Button variant={"contained"} color={"primary"} onClick={() => {
                                    NotificationPopup.success(`已複製連結`);
                                    navigator.clipboard.writeText(
                                        `${window.location.origin}/front-stage/${editLaborContract.uuid}`);
                                }}>
                                    複製公開連結
                                </Button>
                            </div>
                        </Grid>
                    }
                    <Grid item>
                        <ConfirmButton buttonProps={{color: "success"}} onConfirm={() => {
                            onDuplicate(editLaborContract.id)
                        }} message={`是否要新增一份此勞報單的副本？`}>
                            新增副本
                        </ConfirmButton>
                    </Grid>
                    <Grid item container spacing={2} style={{display: "flex", flex: 1, justifyContent: "end", alignItems: "baseline"}}>
                        {
                            editLaborContract.status == LaborContractStatusEnum.Finished &&
                            (calculatedAmount.insuranceAmount != 0 || calculatedAmount.taxAmount != 0) &&
                            <div style={{color: "red"}}>
                                {`繳費去 --->`}
                            </div>
                        }
                        {
                            editLaborContract.status == LaborContractStatusEnum.Finished &&
                            calculatedAmount.insuranceAmount != 0 &&
                            <Grid item>
                                <InsuranceButton></InsuranceButton>
                            </Grid>
                        }
                        {
                            editLaborContract.status == LaborContractStatusEnum.Finished &&
                            calculatedAmount.taxAmount != 0 &&
                            <Grid item>
                                <TaxButton laborContract={editLaborContract} taxAmount={calculatedAmount.taxAmount}></TaxButton>
                            </Grid>
                        }
                    </Grid>

                </Grid>

                <Grid item xs={10} alignItems={"center"}>
                    <EditTextField setter={onChange("name")} value={editLaborContract?.name} label={"專案名稱"}/>
                </Grid>
                <Grid item xs={2} style={{marginBottom: 10, paddingLeft: 10}}>
                    <StyleTextField value={LaborContractStatusMap[editLaborContract.status]}
                                    label={"狀態"} inputProps={{InputProps: {readOnly: true}}}/>
                </Grid>
                <Grid item style={{width: "100%"}}>
                    <LaborContractView
                        editLaborContract={editLaborContract}
                        setEditLaborContract={setEditLaborContract}
                        isGranted={true}
                    />
                </Grid>

            </Grid>
        </>
    )
}
