import React  from "react";
import {ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache} from "@apollo/client";
import {Outlet} from "react-router-dom";

const DefaultLayout =({children}:{children:any})=> <div>
    {children}
</div>




const App = () => {
    const client = new ApolloClient({
        cache: new InMemoryCache({}),
        link:ApolloLink.from([new HttpLink({
            uri:`${process.env.REACT_APP_BACKEND_URI}/graphql`,
        })]),
    });
    return (
        <ApolloProvider client={client}>
            {/*<ThemeProvider>*/}
                {/*<GlobalStyles />*/}
                {/*<ErrorBoundary>*/}
                <DefaultLayout>
                    <Outlet/>
                </DefaultLayout>
                {/*</ErrorBoundary>*/}
            {/*</ThemeProvider>*/}
        </ApolloProvider>
    );
};

export default App;
