import {
    Authorization,
    Company,
    Contractor, IncomeTypeEnum, Maybe, RoleEnum, TaxClassificationEnum,
    useGetCompaniesQuery,
    useGetContractorsQuery,
    useGetMeQuery,
    User
} from "../generated/graphql";
import {useEffect, useState} from "react";
import {useUser} from "../auth/Auth";
import {GridCellParams, GridLocaleText, GridValueGetterParams} from '@mui/x-data-grid-pro';
import {useNavigate, useSearchParams} from "react-router-dom";
import {find, get} from "lodash";
import {add, format, parseISO} from "date-fns";

export const useCompanies = (): Array<Partial<Company> | undefined> | undefined => {
    const [companies, setCompanies] = useState<Array<Partial<Company> | undefined> | undefined>(undefined);
    const {data, error} = useGetCompaniesQuery();
    useEffect(() => {
        if (!error && data) {
            setCompanies(data.companies as any);
        }
    }, [data, setCompanies, error])

    return companies;
}

export const useContractors = (): Array<Partial<Contractor> | undefined> | undefined => {
    const [contractors, setContractors] = useState<any>(undefined);
    const {data, error} = useGetContractorsQuery();
    useEffect(() => {
        if (error) {
            throw new Error(`Error setting user in hook useCompanies ${error.message}`);
        }
        if (data) {
            setContractors(data.contractors);
        }
    }, [data, setContractors, error])

    return contractors;
}

export const formatCurrency = (number) => {
    const formatter = new Intl.NumberFormat('zh-TW', {maximumFractionDigits: 0});
    if (number) {
        return formatter.format(number);
    }
    else {
        return 0;
    }
}

export const calculateContractTotals = (
    amount, taxClassification: TaxClassificationEnum, incomeType: IncomeTypeEnum, noInsurance?: Maybe<boolean>|undefined) => {
    let insuranceAmount = 0;
    let taxAmount = 0;
    // calculate insurance
    if (!noInsurance) {
        if (taxClassification == TaxClassificationEnum.Citizen ||
            taxClassification == TaxClassificationEnum.ResidentAlien) {
            let firstLevel = 0;
            if (incomeType == IncomeTypeEnum.A_9a || incomeType == IncomeTypeEnum.A_9b) {
                firstLevel = 20000;
            } else if (incomeType == IncomeTypeEnum.A_50) {
                firstLevel = 26400;
            }
            if (firstLevel != 0) {
                if (firstLevel <= amount && amount <= 10000000) {
                    insuranceAmount = amount * 0.0211;
                } else if (10000000 < amount) {
                    insuranceAmount = 10000000 * 0.0211;
                }
            }
        }
    }
    // calculate tax
    if (taxClassification == TaxClassificationEnum.Citizen ||
        taxClassification == TaxClassificationEnum.ResidentAlien) {
        if (incomeType == IncomeTypeEnum.A_9a || incomeType == IncomeTypeEnum.A_9b) {
            if (20000 < amount) {
                taxAmount = amount * 0.1;
            }
        } else if (incomeType == IncomeTypeEnum.A_50) {
            if (86001 <= amount) {
                taxAmount = amount * 0.05;
            }
        }
    } else if (taxClassification == TaxClassificationEnum.NonResidentAlien) {
        if (incomeType == IncomeTypeEnum.A_9a) {
            taxAmount = amount * 0.2;
        } else if (incomeType == IncomeTypeEnum.A_9b) {
            if (5000 < amount) {
                taxAmount = amount * 0.2;
            }
        } else if (incomeType == IncomeTypeEnum.A_50) {
            if (amount <= 39601) {
                taxAmount = amount * 0.06;
            } else if (39601 < amount) {
                taxAmount = amount * 0.18;
            }
        } else if (incomeType == IncomeTypeEnum.A_92) {
            taxAmount = amount * 0.2;
        }
    }
    return {insuranceAmount: Math.round(insuranceAmount), taxAmount: Math.floor(taxAmount)}
}

export const calculateAmountFromNetAmount = (
    netAmount, taxClassification: TaxClassificationEnum, incomeType: IncomeTypeEnum, noInsurance: Maybe<boolean>|undefined
) => {
    let insuranceAmount = 0;
    let taxAmount = 0;
    // calculate insurance
    if (!noInsurance) {
        if (taxClassification == TaxClassificationEnum.Citizen ||
            taxClassification == TaxClassificationEnum.ResidentAlien) {
            let firstLevel = 0;
            if (incomeType == IncomeTypeEnum.A_9a || incomeType == IncomeTypeEnum.A_9b) {
                if (20000 <= netAmount && netAmount <= 10000000) {
                    insuranceAmount = netAmount / 0.8789 * 0.0211
                } else if (10000000 < netAmount) {
                    insuranceAmount = 211000
                }
            } else if (incomeType == IncomeTypeEnum.A_50) {
                if (24000 <= netAmount && netAmount < 84501) {
                    insuranceAmount = netAmount / 0.9789 * 0.0211
                } else if (84501 <= netAmount && netAmount <= 10000000) {
                    insuranceAmount = netAmount / 0.9289 * 0.0211
                } else if (10000000 < netAmount) {
                    insuranceAmount = 211000
                }
            }
        }
    }
    // calculate tax
    if (taxClassification == TaxClassificationEnum.Citizen ||
        taxClassification == TaxClassificationEnum.ResidentAlien) {
        if (incomeType == IncomeTypeEnum.A_9a || incomeType == IncomeTypeEnum.A_9b) {
            if (20000 <= netAmount) {
                taxAmount = netAmount / 0.8789 * 0.1;
            }
        } else if (incomeType == IncomeTypeEnum.A_50) {
            // console.log(netAmount)
            if (84501 <= netAmount) {
                taxAmount = netAmount / 0.9289 * 0.05;
            }
        }
    } else if (taxClassification == TaxClassificationEnum.NonResidentAlien) {
        if (incomeType == IncomeTypeEnum.A_9a) {
            taxAmount = netAmount / 0.8 * 0.2;
        } else if (incomeType == IncomeTypeEnum.A_9b) {
            if (4000 < netAmount) {
                taxAmount = netAmount / 0.8 * 0.2;
            }
        } else if (incomeType == IncomeTypeEnum.A_50) {
            if (netAmount <= 33840) {
                taxAmount = netAmount / 0.94 * 0.06;
            } else if (33840 < netAmount) {
                taxAmount = netAmount / 0.82 * 0.18;
            }
        } else if (incomeType == IncomeTypeEnum.A_92) {
            taxAmount = netAmount / 0.8 * 0.2;
        }
    }
    return netAmount+Math.round(insuranceAmount)+Math.floor(taxAmount)
}

export const HasRole = ({roles,children}:{roles:RoleEnum[],children:any})=>
{
    const user = useUser();
    if (hasRole(user, roles))
    {
        return children;
    }
    else
    {
        return null;
    }
}

export function hasRole(user: Partial<User>|undefined, roles?: RoleEnum[]) {
    return user?.roles?.find((role)=>roles?.includes(role)) ? true : false
}
export function hasAuthorization(user: Partial<User>|undefined, authorizations?: Authorization[]) {
    return user?.authorizations?.find((role)=>authorizations?.includes(role)) ? true : false
}


export const DataGridLocale:Partial<GridLocaleText> = {
    // booleanCellFalseLabel: "",
    // booleanCellTrueLabel: "",
    // checkboxSelectionHeaderName: "",
    // filterValueAny: "",
    // filterValueFalse: "",
    // filterValueTrue: "",
    // footerRowSelected(count: number): React.ReactNode
    // {
    //     return undefined;
    // },
    // footerTotalRows: undefined,
    // noResultsOverlayLabel: "",
    // toolbarColumns: undefined,
    // toolbarColumnsLabel: "",
    // toolbarDensity: undefined,
    // toolbarDensityComfortable: "",
    // toolbarDensityCompact: "",
    // toolbarDensityLabel: "",
    // toolbarDensityStandard: "",
    toolbarExport: "輸出報表",
    toolbarExportCSV: "下載CSV檔",
    // Root
    // rootGridLabel: "表單",
    noRowsLabel: "沒有任何內容",
    errorOverlayDefaultLabel: "錯誤發生",
    // Column menu text
    columnMenuLabel: "Menu",
    columnMenuShowColumns: "顯示",
    columnMenuFilter: "篩選",
    columnMenuHideColumn: "隱藏",
    columnMenuUnsort: "不指定排序",
    columnMenuSortAsc: "由小到大",
    columnMenuSortDesc: "由大到小",
    // Columns panel text
    columnsPanelTextFieldLabel: "尋找欄位",
    columnsPanelTextFieldPlaceholder: "欄位名稱",
    columnsPanelDragIconLabel: "重新排序欄位",
    columnsPanelShowAllButton: "顯示全部",
    columnsPanelHideAllButton: "隱藏全部",
    // Filter panel text
    filterPanelAddFilter: "增加篩選",
    filterPanelDeleteIconLabel: "刪除",
    filterPanelOperators: "篩選條件",
    filterPanelOperatorAnd: "And",
    filterPanelOperatorOr: "Or",
    filterPanelColumns: "欄位",
    filterPanelInputLabel: "值",
    filterPanelInputPlaceholder: "輸入值",
    // Filter operators text
    filterOperatorContains: "包含",
    filterOperatorEquals: "等於",
    filterOperatorStartsWith: "開頭是",
    filterOperatorEndsWith: "結尾是",
    filterOperatorIs: "等於",
    filterOperatorNot: "不等於",
    filterOperatorIsAnyOf: "屬於任何",
    filterOperatorAfter: "晚於",
    filterOperatorOnOrAfter: "晚於(包含)",
    filterOperatorBefore: "早於",
    filterOperatorOnOrBefore: "早於(包含)",
    filterOperatorIsEmpty: "等於空值",
    filterOperatorIsNotEmpty: "不是空值",

    // Filters toolbar button text
    toolbarFilters: "篩選",
    toolbarFiltersLabel: "顯示篩選",
    toolbarFiltersTooltipHide: "隱藏篩選",
    toolbarFiltersTooltipShow: "顯示篩選",
    toolbarFiltersTooltipActive: (count) => `${count}個篩選條件`,
    // Column header text
    columnHeaderFiltersTooltipActive: (count) => `${count}個篩選條件`,
    columnHeaderFiltersLabel: "顯示篩選",
    columnHeaderSortIconLabel: "排序",
    pinToLeft: "釘選至左邊",
    pinToRight: "釘選至右邊",
    unpin: "取消釘選",

    noResultsOverlayLabel:"沒有資料",
};

export const useTabIndex = (): [number, (n: number) => void] => {
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        setIndex(
            searchParams.get("index")
                ? parseInt(searchParams.get("index") as string)
                : 0
        );
    }, []);
    useEffect(() => {
        navigate({
            search: `?index=${index}`,
        }, {replace:true});
    }, [index])
    return [index, setIndex];
};

export const DefaultValueGetter = (params: GridValueGetterParams) => {
    return get(params.row, params.field);
};

export const DatetimeValueGetter = (params: GridValueGetterParams) => {
    return format(new Date(get(params.row, params.field)), "yyyy-MM-dd HH:mm");
}

export const DateValueGetter = (params: GridValueGetterParams) => {
    // console.log(params.row);
    if (get(params.row, params.field) == null) {
        return '--';
    }
    return format(new Date(get(params.row, params.field)), "yyyy-MM-dd");
}

export const checkAlert = (company, authorization) => {
    if (!company) {
        return;
    }
    const date = window.localStorage.getItem(`${authorization}_ALERT_DATE`);
    const showAlert = () => {
        alert(`使用期限即將到期，請與直誠續約；\n若無續約意願，請進行備份，資料將於到期日隔天刪除。`);
        window.localStorage.setItem(`${authorization}_ALERT_DATE`,new Date().toISOString());
    }
    const companyAuthorization =
        find(company.companyAuthorizations, (companyAuthorization)=>companyAuthorization.authorization==authorization);
    if (companyAuthorization?.remindDate != null) {
        if (new Date(companyAuthorization?.remindDate) < new Date()) {
            if (date) {
                const expireTimeStamp = add(parseISO(date), {days:1});
                // console.log(new Date(), expireTimeStamp);
                if(new Date() > expireTimeStamp) {
                    showAlert();
                }
            }
            else {
                showAlert();
            }
        }
    }

}