import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import theme from "../theme/Theme";
import {Salary, SalaryItemTypeEnum} from "../generated/graphql";
import {format} from "date-fns";
import {filter, reduce} from "lodash";

export default ({salary}: { salary: Salary }) =>
{
    const [open, setOpen] = useState(false);
    const itemMap = (item) =>
    {
        return <Grid container>
            <Grid item xs={6}>{item.title}</Grid>
            <Grid item xs={6}>{item.amount}</Grid>
        </Grid>
    }
    const itemsList = [
        {title: "薪資結構", items: filter(salary.salaryItems, (item) => item.type == SalaryItemTypeEnum.Normal)},
        {title: "加項", items: filter(salary.salaryItems, (item) => item.type == SalaryItemTypeEnum.Plus)},
        {title: "減項", items: filter(salary.salaryItems, (item) => item.type == SalaryItemTypeEnum.Minus)},
    ]
    return <div>
        <Dialog open={open} fullWidth={true} maxWidth={"sm"}>
            <DialogTitle style={{backgroundColor: theme.palette.primary.dark}}>
                <Typography variant="h3" align="center">
                    {salary.employee?.company?.name}
                </Typography>
                <Typography variant="h4" align="center">
                    {salary.employee?.name}&nbsp;{salary.salaryGroup?.yearMonth?.replace(/(\d{4})(\d{2})/, "$1/$2")}薪資單
                </Typography>
            </DialogTitle>
            <DialogContent style={{padding: 40, border: "2px solid black"}}>
                <Grid direction={"column"} container spacing={5}>
                    <Grid item container>
                        <Grid item xs={3}>計算區間</Grid>
                        <Grid item>{format(new Date(salary.salaryGroup?.startDate), "yyyy-MM-dd")}~
                            {format(new Date(salary.salaryGroup?.endDate), "yyyy-MM-dd")}</Grid>
                    </Grid>
                    {itemsList.map((itemsGrid) =>
                    {
                        return <Grid item container style={{marginTop: 20}}>
                            <Grid item xs={13} style={{borderBottom: "2px solid black"}}>{itemsGrid.title}</Grid>
                            <Grid item xs={5}></Grid>
                            <Grid item xs={7} container>
                                {itemsGrid.items.map(itemMap)}
                            </Grid>
                        </Grid>
                    })}
                    <Grid item container>
                        <Grid item xs={13} style={{borderBottom: "2px solid black"}}></Grid>
                        <Grid item xs={3}>應發金額</Grid>
                        <Grid item xs={9} container style={{justifyContent: "center"}}>
                            {reduce(salary.salaryItems, (acc, item: any) => acc + ([SalaryItemTypeEnum.Normal, SalaryItemTypeEnum.Plus].includes(item.type) ? item.amount : 0), 0)}
                        </Grid>
                        <Grid item xs={3}>應減金額</Grid>
                        <Grid item xs={9} container style={{justifyContent: "center"}}>
                            {reduce(salary.salaryItems, (acc, item: any) => acc + (item?.type == SalaryItemTypeEnum.Minus ? item.amount : 0), 0)}
                        </Grid>
                        <Grid item xs={3}>實際發放金額</Grid>
                        <Grid item xs={9} container style={{justifyContent: "center"}}>
                            {reduce(salary.salaryItems, (acc, item: any) =>
                            {
                                const multiply = [SalaryItemTypeEnum.Normal, SalaryItemTypeEnum.Plus].includes(item.type) ? 1 :
                                    (item.type == SalaryItemTypeEnum.Minus) ? -1 : 0;
                                return acc + item.amount * multiply;
                            }, 0)}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() =>
                    {
                        setOpen(false);
                    }}
                >
                    關閉
                </Button>
            </DialogActions>
        </Dialog>
        <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpen(true)}
        >
            查看
        </Button>
    </div>
}