import {colors, createTheme} from '@mui/material';
import shadows from './Shadows';
import typography from './Typography';

const theme = createTheme({
  palette: {
    background: {
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#C2512B',
      dark: '#FFCDBF',
    },
    secondary: {
      main: '#898989'
      // main: colors.blueGrey
    },
    success: {
      main: '#DE5B00',
      contrastText: '#FFFFFF'
    },
    info: {
      main: colors.grey[600],
      contrastText: colors.grey[300],
    },
    text: {
      primary: '#231815',
      // primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
    error: {
        main: colors.red[500]
    },
    divider: "#D05535",

  },
  typography
});

export default theme;