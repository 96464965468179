import {Box, Avatar, Divider, Drawer, Hidden, Typography, Select, MenuItem, Button} from "@mui/material";
import {makeStyles} from '@mui/styles';
import React, {useContext, useEffect} from "react";
import {Link, NavLink as RouterLink, useLocation} from "react-router-dom";
import {logout, useCompany, useUser} from "../auth/Auth";
import {
    BookOpen,
    Briefcase, Home, Paperclip,
} from "react-feather";
import preval from "preval.macro";
import MyMenuItem from "./MyMenuItem";
import {Authorization, RoleEnum} from "../generated/graphql";
import {hasAuthorization, hasRole} from "../common/Utils";
import Logo from "../image/logo.png";
import UserIcon from "../image/user.svg";
import CompanyIcon from "../image/company.svg";
import LaborContractIcon from "../image/labor_contract.svg";
import ReportIcon from "../image/report.svg";
import ContractorIcon from "../image/contractor.svg";
import {find} from "lodash";
import {CompanyContext} from "../routes";
import {format} from "date-fns";
import {AuthorizationMap} from "../common/Constant";
import theme from "../theme/Theme";
import ConfirmButton from "../component/ConfirmButton";

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 200,
    },
    desktopDrawer: {
        width: 200,
        height: "calc(100%)",
    },
    avatar: {
        cursor: "pointer",
        width: 64,
        height: 64,
    },
}));
const items = [
    {
        href: "/user",
        icon: UserIcon,
        title: "使用者",
        roles: [RoleEnum.Admin, RoleEnum.Manager],
    },
    {
        href: "/company",
        icon: CompanyIcon,
        title: "公司列表",
        roles: [RoleEnum.Admin],
    },
    {
        href: "/laborContract",
        icon: LaborContractIcon,
        title: "勞報單",
        authorizations: [Authorization.LaborContract],
    },
    {
        href: "/contractor",
        icon: ContractorIcon,
        title: "廠商",
        authorizations: [Authorization.LaborContract],
    },
    {
        href: "/report",
        icon: ReportIcon,
        title: "財務報表",
        authorizations: [Authorization.Finance],
    },
    {
        href: "/employee",
        icon: UserIcon,
        title: "人事管理",
        authorizations: [Authorization.Personnel],
    },
    {
        href: "/leave",
        icon: ReportIcon,
        title: "假勤管理",
        authorizations: [Authorization.Personnel],
    },
    {
        href: "/salary",
        icon: ReportIcon,
        title: "薪資管理",
        authorizations: [Authorization.Personnel],
    },

]

const MenuBar = ({onMobileClose, openMobile, setCompany}) => {
    const classes = useStyles();
    const location = useLocation();
    const user = useUser();
    useEffect(()=>{
        if (company==null) {
            const companyId = window.localStorage.getItem("companyId");
            let company:any = null;
            if (companyId) {
                company = find(user?.companies, (company)=>company.id==parseInt(companyId));
                if (company) {
                    setCompany(company);
                }
            }
            if (!company) {
                setCompany(user?.companies![0]);
            }

        }
    },[user]);

    const company = useContext(CompanyContext);

    let companyAuthorization:any = null;
    if (location.pathname.includes('laborContract') || location.pathname.includes('contractor')) {
        companyAuthorization = find(company?.companyAuthorizations, ({authorization})=>authorization==Authorization.LaborContract);
    }
    else if (location.pathname.includes('report')) {
        companyAuthorization = find(company?.companyAuthorizations, ({authorization})=>authorization==Authorization.Finance);
    }
    else if (location.pathname.includes('employee') || location.pathname.includes('leave') || location.pathname.includes('salary')) {
        companyAuthorization = find(company?.companyAuthorizations, ({authorization})=>authorization==Authorization.Personnel);
    }
    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                p={2}
            >
                <Avatar
                    className={classes.avatar}
                    component={Link}
                    src={user?.picture as string}
                    to="/app/account"
                />
                <Typography
                    color="textPrimary"
                    variant="h5"
                    style={{marginBottom: 10}}
                >
                    {user?.name}
                </Typography>
                {   (user?.companies?.length as any > 1) ?
                    <Select value={parseInt(company?.id as any)} onChange={({target}) =>
                    {
                        const company = find(user?.companies, (company) => company.id == target.value);
                        setCompany(company);
                        window.localStorage.setItem("companyId", `${company?.id}`);
                    }}>
                        {user?.companies?.map((company) =>
                        {
                            return <MenuItem key={company.id} value={company.id}>
                                {company.name}
                            </MenuItem>
                        })}
                    </Select> : user?.companies![0]?.name
                }
                {companyAuthorization &&
                <Typography color="textSecondary" variant="body2">
                    <div>
                        {(companyAuthorization.startDate || companyAuthorization.endDate) && AuthorizationMap[companyAuthorization.authorization]}<br/>
                        {companyAuthorization.startDate && <>開始日：{format(new Date(companyAuthorization.startDate), 'yyyy-MM-dd')}<br/></>}
                        {companyAuthorization.endDate && <>到期日：{format(new Date(companyAuthorization.endDate), 'yyyy-MM-dd')}</>}
                    </div>
                </Typography>}
            </Box>
            <Divider style={{borderColor: "#DDD"}}/>
            <Box
                p={2}
                alignItems="center"
                display="flex"
                flexDirection="column"
            >
                {items.map((item) => {
                    let menuItem = <MyMenuItem
                        style={{width: 150}}
                        href={item.href}
                        key={item.title}
                        title={item.title}
                        icon={item.icon}
                        selected={location.pathname==item.href}
                    />
                    if (item.roles && !hasRole(user, item.roles)) {
                    }
                    else if (item.authorizations && !hasAuthorization(user, item.authorizations)) {
                    }
                    else {
                        return menuItem;
                    }
                })}
                <ConfirmButton
                    buttonProps={{color: "success"}}
                    onConfirm={() => {
                        logout();
                        window.location.reload();
                    }}>
                    登出
                </ConfirmButton>
                <img src={Logo} style={{width: 66, height: 66, marginTop: 20}}/>
            </Box>
        </Box>
    );
    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.mobileDrawer}}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.desktopDrawer}}
                    open
                    variant="persistent"
                    PaperProps={{style: {borderColor: "#DDD"}}}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    )
}

export default MenuBar;