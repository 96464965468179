import React, {useCallback, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl, FormControlLabel, Grid,
    InputLabel,
    MenuItem, Radio, RadioGroup,
    Select, TextField,
    Typography
} from "@mui/material";
import {LaborContractStatusMap} from "../common/Constant";
import {map} from "lodash";
import {LaborContractStatusEnum} from "../generated/graphql";
import {getAccessToken} from "../auth/Auth";
import { saveAs } from "file-saver";
import NotificationPopup from "../common/NotificationPopup";
import theme from "../theme/Theme";

type StatusSelectType = LaborContractStatusEnum | "all"

export default ({company}) => {
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState<StatusSelectType>("all");
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const [payDateSelected, setPayDateSelected] = useState(false);
    const title = "匯出勞報單"
    const download = useCallback(async() => {
        if (!(startDate && endDate)) {
            NotificationPopup.error(`請選擇起迄日期`);
            return;
        }
        let url = `${process.env.REACT_APP_BACKEND_URI}/labor-contracts/report?companyId=${company.id}`
        if (status!="all") {
            url = url + `&status=${status}`;
        }
        url = url + `&${payDateSelected?"payDateFrom":"startDate"}=${startDate}&${payDateSelected?"payDateTo":"endDate"}=${endDate}`
        const res = await fetch(
            url,
            {
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            }
        );
        const blob = await res.blob();
        saveAs(blob, `${company.name}_勞報單列表.xlsx`);
    }, [status, startDate, endDate, payDateSelected])
    return (
        <div>
            <Dialog open={open} fullWidth={true}>
                <DialogTitle style={{backgroundColor: theme.palette.primary.dark}}>
                    <Typography variant="h4" align="center">
                        {title}
                    </Typography>
                </DialogTitle>
                <FormControl style={{margin: 10}}>
                    <InputLabel>狀態</InputLabel>
                    <Select label={"狀態"}  value={status} onChange={({target}) => {
                        setStatus(target.value as StatusSelectType)
                    }}>
                        <MenuItem key={"all"} value={"all"}>所有狀態</MenuItem>
                        {map(LaborContractStatusMap, (value, key) => {
                            return <MenuItem key={key} value={key}>{value}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <Grid container direction={"column"}>
                    <RadioGroup defaultValue="contract" onChange={({target})=>{
                        setPayDateSelected(target.value=="paydate");
                    }}>
                        <Grid container direction={"row"} style={{marginLeft: 30}} alignItems={"center"}>
                            <Grid container item direction={"column"} xs={3}>
                                <FormControlLabel value="contract" control={<Radio/>} label="勞務期間"/>
                                <FormControlLabel value="paydate" control={<Radio/>} label="支付日期"/>
                            </Grid>
                            <TextField
                                InputProps={{startAdornment: <></>}}
                                variant={"outlined"}
                                style={{margin: 10}}
                                type={"date"}
                                onChange={({target}) => {
                                    setStartDate(target.value)
                                }}
                                label={`${payDateSelected ? "支付日期" : "勞務期間"} 從`}
                            >

                            </TextField>
                            <TextField
                                InputProps={{startAdornment: <></>}}
                                variant={"outlined"}
                                style={{margin: 10}}
                                type={"date"}
                                onChange={({target}) => {
                                    setEndDate(target.value)
                                }}
                                label={`到`}>
                            </TextField>

                        </Grid>
                    </RadioGroup>
                </Grid>

                <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        type={"submit"}
                        onClick={() => {
                            download();
                            setOpen(false);
                        }}
                    >
                        下載
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        取消
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => setOpen(true)}
            >
                {title}
            </Button>
        </div>
    )
}