import {Document, Font, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import {IncomeTypeEnum, LaborContract, PaymentMethodEnum, TaxClassificationEnum} from "../../generated/graphql";
import {getAccessToken} from "../../auth/Auth";
import {IncomeTypeMap, PaymentMethodMap, TaxClassificationMap} from "../../common/Constant";
import {calculateContractTotals, formatCurrency} from "../../common/Utils";
import {format} from "date-fns";

const styles = StyleSheet.create(
    {
        container: {
            flex: 1,
            flexDirection: "column",
            fontFamily: "tapeiSans",
            fontSize: "12pt",
            lineHeight: "1.4pt"
        },
        page:
            {
                padding: "30pt",
                paddingTop: "20pt"
                //DONT PUT FONT FAMILY HERE IT WILL BREAK
            },
        header:
            {
                fontSize: "25pt",
                textAlign: "center"
            },
        title:
            {
                // border:"1pt 1pt 0pt 1pt solid black",
                borderLeft: "1pt solid black",
                borderRight: "1pt solid black",
                borderTop: "1pt solid black",
                backgroundColor: "#eeeeee",
                padding: "10pt",
                paddingBottom: "5pt",
                display: "flex",
                textAlign: "center",
                alignItems: "center",

            },
        column:
            {
                flexDirection: "column",
                padding: "10pt",
                width: "50%"
            },
        photoColumn:
            {
                flexDirection: "column",
                padding: "10pt",
                width: "50%",
                height: "100vw"
            },
        row:
            {
                display: "flex",
                borderLeft: "1pt solid black",
                borderRight: "1pt solid black",
                borderTop: "1pt solid black",
                flexDirection: "row",
                justifyContent: "center",
            },
        text:
            {
                border: "1pt solid black",
            },
        idImage:
            {
                // maxWidth:"150pt",
                maxHeight: "200pt",
                objectFit: "contain",
                textAlign: "center",
                alignItems: "center",
            },
        signature:
            {
                maxWidth: "150pt",
                maxHeight: "50pt"
            },
        passbook:
            {
                padding: "10pt",
                maxWidth: "400pt",
                maxHeight: "300pt",
            },
        minHeight50:
            {
                minHeight: "50pt"
            }
    })

const Header = ({children}) => {
    return <Text style={styles.header}>
        {children}
    </Text>
}

const Title = ({children}) => {
    return <Text style={styles.title}>
        {children}
    </Text>
}

Font.register({
    family: 'tapeiSans',
    src: '/font/TaipeiSansTCBeta-Light.ttf'
});
export default ({laborContract}: { laborContract: LaborContract }) => {
    const idFront = laborContract.idFrontFile;
    const idBack = laborContract.idBackFile;
    const signature = laborContract.signatureFile;
    const passBook = laborContract.passbookFile;
    const {
        contractorName,
        contractorAddress,
        contractorContactAddress,
        contractorEmail,
        contractorIdNumber,
        contractorPhone,
        contractorBankCode,
        paymentMethod,
        contractorTaxClassification,
        contractorBankAccountNumber,
        incomeType,
        amount,
        contractorNoInsurance,
        contractContent,
        company,
        contractorNationality,
        contractorBankAccountName,
        startDate,
        endDate,
    } = laborContract;
    const {insuranceAmount, taxAmount} = calculateContractTotals(
        laborContract.amount,
        laborContract.contractorTaxClassification as TaxClassificationEnum,
        laborContract.incomeType as IncomeTypeEnum,
        laborContract.contractorNoInsurance,
    )
    const t = (val) => val || "無";
    const isCitizen = contractorTaxClassification == TaxClassificationEnum.Citizen
    return <Document author={"Codecity"}>
        <Page size="A4" style={styles.page}>
            <View style={styles.container}>
                <Header>
                    {company.name} 勞務報酬單
                </Header>
                <Text style={{
                    width: "100%",
                    textAlign: "right"
                }}>支付日期：{format(new Date(laborContract.payDate), "yyyy-MM-dd")}</Text>
                <Title>所得人</Title>
                <View style={styles.row}>
                    <View style={styles.column}>
                        <Text>姓名：{t(contractorName)}</Text>
                        <Text>電話：{t(contractorPhone)}</Text>
                        <Text>Email：{t(contractorEmail)}</Text>
                    </View>
                    <View style={[styles.column, {borderLeft: "1pt solid #000"}]}>
                        <Text>國籍：{TaxClassificationMap[contractorTaxClassification || ""]}</Text>
                        {contractorNationality ? <Text>國家：{contractorNationality}</Text> : null}
                        <Text>身分證：{t(contractorIdNumber)}</Text>
                        <Text>免扣二代健保：{contractorNoInsurance ? "是" : "否"}</Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={[styles.column, {width: "100%"}]}>
                        <Text>
                            戶籍地址：{contractorAddress}
                        </Text>
                        <Text>
                            通訊地址：{contractorContactAddress}
                        </Text>
                    </View>
                </View>
                <Title>{isCitizen ? "身分證" : "居留證/護照"}</Title>
                <View style={[styles.row]}>
                    {idFront && <View style={isCitizen ? [styles.column] : {margin: 10}}>
                        <Image style={styles.idImage} src={`${idFront?.uri}?token=${getAccessToken()}`}/>
                    </View>}
                    {isCitizen && <View style={[styles.column, {borderLeft: "1pt solid #000"}]}>
                        {idBack && <Image style={styles.idImage} src={`${idBack?.uri}?token=${getAccessToken()}`}/>}
                    </View>}
                </View>
                <Title>
                    勞務內容
                </Title>
                <View style={styles.row}>
                    <View style={[styles.column, {width: "100%"}]}>
                        <Text>
                            勞務內容： {contractContent}
                        </Text>
                        <Text>
                            所得類別： {IncomeTypeMap[incomeType as IncomeTypeEnum]}
                        </Text>
                        <Text>
                            勞務期間：{format(new Date(startDate), "yyyy-MM-dd")}&nbsp;~&nbsp;
                            {format(new Date(endDate), "yyyy-MM-dd")}
                        </Text>
                    </View>
                </View>
                <Title>
                    領款金額
                </Title>
                <View style={[styles.row, {borderBottom: "1pt solid black"}]}>
                    <View style={styles.column}>
                        <Text>
                            支領金額： {formatCurrency(amount)}
                        </Text>
                        <Text>
                            代扣所得稅： {formatCurrency(taxAmount)}
                        </Text>
                        <Text>
                            二代健保： {formatCurrency(insuranceAmount)}
                        </Text>
                        <Text>
                            支領淨額：{formatCurrency((amount as number) - taxAmount - insuranceAmount)}
                        </Text>
                        <Text>
                            付款方式： {PaymentMethodMap[paymentMethod as PaymentMethodEnum]}
                        </Text>
                    </View>
                    <View style={[styles.column, {borderLeft: "1pt solid #000"}]}>
                        <Text>
                            領款人簽名：
                        </Text>
                        <View style={[styles.column]}>
                            {signature &&
                            <Image style={styles.signature} src={`${signature?.uri}?token=${getAccessToken()}`}/>}
                        </View>
                        {signature &&
                        <Text>
                            簽名日期：{format(new Date(signature?.createdAt), "yyyy-MM-dd")}
                        </Text>}
                    </View>
                </View>
            </View>
        </Page>

        <Page size="A4" style={styles.page}>
            <View style={styles.container}>
                <Title>
                    匯款帳號
                </Title>
                <View style={[styles.row]}>
                    <View style={[styles.column, {width: "100%"}]}>
                        <Text>
                            銀行代碼： {contractorBankCode}
                        </Text>
                        <Text>
                            匯款帳號： {contractorBankAccountNumber}
                        </Text>
                        <Text>
                            帳號戶名： {contractorBankAccountName}
                        </Text>
                    </View>
                </View>
                <View style={[styles.row, {borderLeft: "1pt solid #000"}, {borderBottom: "1pt solid black"}]}>
                    {passBook &&
                    <Image style={styles.passbook} src={`${passBook?.uri}?token=${getAccessToken()}`}/>}
                </View>
            </View>
        </Page>
    </Document>
}
