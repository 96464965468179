import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import theme from "../theme/Theme";
import {useNavigate} from "react-router-dom";


export default () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    return <div>
        <Dialog open={open} maxWidth="xl" fullWidth={true}>
            <DialogTitle style={{backgroundColor: theme.palette.primary.dark}}>
                <Typography variant="h4" align="center">
                    二代健保申報繳納之操作說明
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <img src={"/step1.png"} style={{height: "63vh", objectFit: "contain"}}/>
                    &nbsp;&nbsp;
                    <img src={"/step2.png"} style={{height: "63vh", objectFit: "contain"}}/>
                </div>
            </DialogContent>
            <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    type={"submit"}
                    onClick={() => {
                        window.open("https://eservice.nhi.gov.tw/2nd/", "_blank");
                        setOpen(false);
                    }}
                >
                    申報繳納
                </Button>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    取消
                </Button>
            </DialogActions>
        </Dialog>
        <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpen(true)}>
            二代健保
        </Button>
    </div>
}