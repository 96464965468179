import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select,
    TextField,
    Typography
} from "@mui/material";
import theme from "../theme/Theme";
import {useNavigate} from "react-router-dom";
import {IncomeTypeEnum, TaxClassificationEnum} from "../generated/graphql";
import {calculateContractTotals} from "../common/Utils";
import { saveAs } from "file-saver";


export default ({laborContract, taxAmount}) =>
{
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [taxData, setTaxData] = useState<any>();
    const [formData, setFormData] = useState({});
    const payDate = new Date(laborContract.payDate);

    useEffect(() =>
    {
        (async () =>
        {
            if(laborContract) {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URI}/labor-contracts/getTaxData?taxId=${laborContract.company.taxId}`,
                )
                const json = await response.json();
                const year = `${payDate.getFullYear() - 1911}`;
                const month = `${payDate.getMonth()+1}`.padStart(2,"0");
                const day = `${payDate.getDate()}`.padStart(2,"0");
                let incomeData;
                if (laborContract.incomeType == IncomeTypeEnum.A_50) {
                    if (laborContract.contractorTaxClassification == TaxClassificationEnum.NonResidentAlien) {
                        if (laborContract.amount <= 37875) {
                            incomeData = {
                                y5a: year,
                                y5b: year,
                                m5a: month,
                                m5b: month,
                                d5b: day,
                                p5: "1",
                                gamt5: laborContract.amount,
                                pwamt5: taxAmount,
                            }
                        }
                        else {
                            incomeData = {
                                y4a: year,
                                y4b: year,
                                m4a: month,
                                m4b: month,
                                d4b: day,
                                p4: "1",
                                gamt4: laborContract.amount,
                                pwamt4: taxAmount,
                            }
                        }
                    }
                    else {
                        incomeData = {
                            y3a: year,
                            y3b: year,
                            m3a: month,
                            m3b: month,
                            d3b: day,
                            p3: "1",
                            gamt3: laborContract.amount,
                            pwamt3: taxAmount,
                        }
                    }
                }
                else {
                    incomeData = {
                        "pwamt1": laborContract.amount,
                        "pwamt2": laborContract.amount,
                    };
                }
                setTaxData({...json,
                    ...incomeData,
                    "varYear": year,
                    "varMonth": month,
                    "varYear1": year,
                    "varMonth1": month,
                    "varDay1": day,
                    payType: laborContract.incomeType == IncomeTypeEnum.A_50 ? "151" : "152",
                    "payTypeName": laborContract.incomeType == IncomeTypeEnum.A_92  ? "15B 其他所得" : "156 執行業務報酬",
                    "incomePhone": laborContract.operatorPhone,
                    "idf": laborContract.contractorTaxClassification == TaxClassificationEnum.NonResidentAlien ? "2" : "1",
                    "withholdingAmount": taxAmount,
                    "idf1": "N",
                    "covId19":"N",
                    "isConfirm":"true",
                    "toConfirm":"",
                });
            }
        })();
    }, []);

    return <div>
        <Dialog open={open} maxWidth="sm" fullWidth={true}>
            <DialogTitle style={{backgroundColor: theme.palette.primary.dark}}>
                <Typography variant="h4" align="center">
                    代扣所得稅
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <FormControl style={{marginTop: 20, marginBottom: 20}}>
                        <InputLabel>稽徵單位</InputLabel>
                        <Select label="稽徵單位" onChange={({target}) => {
                            setTaxData({...taxData, "dstCd": target.value});
                        }}>
                            {taxData?.dstInfo?.map((dst)=> {
                                return <MenuItem key={dst.dstCd} value={dst.dstCd}>
                                    {dst.dstCd} - {dst.dstNm}[{dst.dstArea}]
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControlLabel control={
                        <Checkbox
                            onChange={({target}) => {
                                setTaxData({...taxData, "idf1": target.checked ? "Y" : "N"})
                            }}
                        />
                    } label="自動補扣繳＊"/>
                </div>
                <br/>
                <br/>
                *扣繳義務人應扣未扣或短扣稅款，在未經檢舉或調查前，依稅捐稽徵法第48條之1規定自動補扣並補繳所扣稅款
                ，選擇「是」自動補扣繳，免加徵滯納金，但應自該稅捐原繳納期限(如遇例假日則順延)截止之次日起，至補繳之日止，
                依各年度1月1日郵政儲金1年期定期儲金固定利率，按日加計利息，一併徵收。
            </DialogContent>
            <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={async () =>
                    {
                        const res = await fetch(
                            `${process.env.REACT_APP_BACKEND_URI}/labor-contracts/downloadTaxPdf`,
                            {
                                method: "POST",
                                body: JSON.stringify(taxData),
                                headers: {
                                    "Content-Type": "application/json",
                                }
                            },
                        );
                        if (res.ok) {
                            const blob = await res.blob();
                            saveAs(blob, `Report_${taxData.payType}_${taxData.varYear1}-${taxData.varMonth1}-${taxData.varDay1}.pdf`);
                        }
                        else {
                            const error = JSON.parse((await res.json()).message).error;
                            alert(`${error.message}\n${JSON.stringify(error.details[0])}`);
                        }
                    }}
                >
                    下載繳款書
                </Button>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() =>
                    {
                        window.open("https://paytax.nat.gov.tw/Main2.aspx?opt=5", "_blank");
                        setOpen(false);
                    }}
                >
                    線上繳款
                </Button>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() =>
                    {
                        setOpen(false);
                    }}
                >
                    取消
                </Button>
            </DialogActions>
        </Dialog>
        <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpen(true)}>
            代扣所得稅
        </Button>
    </div>;
}