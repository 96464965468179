import { CircularProgress } from "@mui/material";


export default ({loading,error,children})=>
{
    if(loading)
    {
        return <CircularProgress/>;
    }
    else if(error) {
        return <p style={{color: "red"}}>
            發生錯誤
            <br/>
            {error.message}
        </p>
    }
    return children;
}
