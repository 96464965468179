import {Button} from "@mui/material";
import React, {useCallback} from "react";
import NotificationPopup from "../common/NotificationPopup";
import {getAccessToken} from "../auth/Auth";
import { saveAs } from "file-saver";

export default ({title, path, filename, companyId, color="primary"}) => {
    const download = useCallback(async(path, companyId) => {
        let url = `${process.env.REACT_APP_BACKEND_URI}${path}?companyId=${companyId}`
        const res = await fetch(
            url,
            {
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            }
        );
        const blob = await res.blob();
        saveAs(blob, `${filename}.xlsx`);
    },[]);
    return <Button
        variant={"contained"}
        color={color as any}
        onClick={async ()=>await download(path, companyId)}
    >
        {title}
    </Button>
}