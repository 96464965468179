import React, {useState} from "react";
import "./App.css";
import {getAccessToken, logout, setAccessToken} from "./auth/Auth";
import {ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache} from "@apollo/client";
import {Outlet, useRoutes} from "react-router-dom";
import {ThemeProvider, Box, Grid} from "@mui/material";
import {onError} from "@apollo/client/link/error";
import Login from "./auth/Login";
import theme from "./theme/Theme";
import {ErrorBoundary} from "./common/ErrorBoundary";
import routes from "./routes";
import {makeStyles} from "@mui/styles";
import Logo from "./image/logo.png";


const Inner = () => {
    const routing = useRoutes(routes);
    return routing;
}
const useStyles = makeStyles((theme: any) => ({

}))

const App = () => {
    const classes = useStyles();
    return (
            <ThemeProvider theme={theme}>
                <ErrorBoundary>
                <Inner/>
                </ErrorBoundary>
            </ThemeProvider>
    );
};

export default App;
