import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import FormRenderer from "../common/FormRenderer";
import {Formik} from "formik";
import React, {useState} from "react";
import {reduce} from "lodash";
import theme from "../theme/Theme";

export default ({title, columns, onSubmit, submitTitle = '新增'}) => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <Dialog open={open} fullWidth={true}>
                <DialogTitle style={{backgroundColor: theme.palette.primary.dark}}>
                    <Typography variant="h4" align="center">
                        {title}
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={reduce(
                        columns,
                        (acc, column) => {
                            return {...acc, [column.name]: column.value};
                        },
                        {}
                    )}
                    onSubmit={(values, bla) => onSubmit(values, bla, setOpen)}
                >
                    {({handleChange, resetForm, handleSubmit, values}) => {
                        columns.map((column) => {
                            if ('onChange' in column) {
                                column.onChange(values[column.name])
                            }
                        })
                        return (
                            <form onSubmit={handleSubmit}>
                                <DialogContent>
                                    <FormRenderer
                                        values={values}
                                        def={columns}
                                        onChange={handleChange}
                                    />
                                </DialogContent>
                                <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
                                    <Button
                                        variant={"contained"}
                                        color={"primary"}
                                        type={"submit"}
                                    >
                                        {submitTitle}
                                    </Button>
                                    <Button
                                        variant={"contained"}
                                        color={"secondary"}
                                        onClick={() => {
                                            setOpen(false);
                                            resetForm();
                                        }}
                                    >
                                        取消
                                    </Button>
                                </DialogActions>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => setOpen(true)}
            >
                {title}
            </Button>
        </div>
    );
};
