import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {useUpsertConfigMutation} from "../generated/graphql";
import theme from "../theme/Theme";


export default ({config, title}) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(config.value);
    const [upsertConfig] = useUpsertConfigMutation();
    const onUpsert = async (key, value) => (
        upsertConfig({
            variables: {
                key,
                config: {
                    key,
                    value,
                }
            },
            refetchQueries: ["getConfig"]
        })
    );
    useEffect(()=> {
        setValue(config?.value)
    },[config]);
    return <div>
        <Dialog open={open} fullWidth={true}>
            <DialogTitle style={{backgroundColor: theme.palette.primary.dark}}>
                <Typography variant="h4" align="center">
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    multiline
                    label="備註"
                    variant={"outlined"}
                    style={{width: "100%", marginTop: 10}}
                    value={value}
                    onChange={({target}) => {
                        setValue(target.value)
                    }}/>
            </DialogContent>
            <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    type={"submit"}
                    onClick={() => {
                        onUpsert(config.key, value)
                        setOpen(false);
                    }}
                >
                    修改
                </Button>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    取消
                </Button>
            </DialogActions>
        </Dialog>
        <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpen(true)}
        >
            {title}
        </Button>

    </div>
}