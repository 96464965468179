import Page from "../common/Page";
import {AppBar, Box, Grid, Tab, Tabs} from "@mui/material";
import TabPanel from "../component/TabPanel";
import {checkAlert, DataGridLocale, DatetimeValueGetter, DefaultValueGetter, useTabIndex} from "../common/Utils";
import {
    Authorization,
    useCreateLeaveItemMutation,
    useCreateLeaveMutation,
    useCreateWorkRecordMutation,
    useGetLeaveItemsQuery,
    useGetLeavesQuery,
    useGetWorkRecordsQuery,
    useRemoveLeaveItemMutation,
    useRemoveWorkRecordMutation,
    useUpdateLeaveItemMutation,
    useUpdateLeaveMutation,
    useUpdateWorkRecordMutation
} from "../generated/graphql";
import DefaultQueryHandler from "../common/DefaultQueryHandler";
import {DataGridPro, GridSortModel} from "@mui/x-data-grid-pro";
import React, {useCallback, useContext, useState} from "react";
import {DayTypeMap, SalaryStandardMap} from "../common/Constant";
import UpdatePopupButton from "../component/UpdatePopupButton";
import NotificationPopup from "../common/NotificationPopup";
import {filter, omit} from "lodash";
import {InputType} from "../common/FormRenderer";
import {CompanyContext} from "../routes";
import {useEmployeeList} from "../auth/Auth";
import ConfirmButton from "../component/ConfirmButton";
import UploadFile from "../common/UploadFile";
import DownloadButton from "../component/DownloadButton";

const leaveMutationColumns = (params?: any) =>
{
    return [
        {
            name: "id",
            label: "ID",
            value: params?.row.id,
            type: InputType.hidden,
        },
        {name: "title", label: "假勤名稱", value: params?.row.title},
        {name: "limit", label: "請假上限", value: params?.row.limit},
        {
            name: "salaryStandard",
            label: "薪資給薪標準",
            value: params?.row.salaryStandard,
            type: InputType.select,
            options: Object.keys(SalaryStandardMap).map((key) => ({value: key, label: SalaryStandardMap[key]}))
        },
        {name: "description", label: "說明", value: params?.row.description},
    ]
}

export const filterByCompany = (list, company) =>
{
    return filter(list, (o) => (o?.company?.id == company?.id) || (o?.employee?.company?.id == company?.id));
}

export default () =>
{
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: "id", sort: "asc"}]);
    const [tabIndex, setTabIndex] = useTabIndex();
    const {loading: leavesLoading, data: leavesData, error: leavesError} = useGetLeavesQuery();
    const {loading: leaveItemsLoading, data: leaveItemsData, error: leaveItemsError} = useGetLeaveItemsQuery();
    const {loading: workRecordsLoading, data: workRecordsData, error: workRecordsError} = useGetWorkRecordsQuery();
    const [createLeave] = useCreateLeaveMutation();
    const [updateLeave] = useUpdateLeaveMutation();
    const [createLeaveItem] = useCreateLeaveItemMutation();
    const [updateLeaveItem] = useUpdateLeaveItemMutation();
    const [removeLeaveItem] = useRemoveLeaveItemMutation();
    const [createWorkRecord] = useCreateWorkRecordMutation();
    const [updateWorkRecord] = useUpdateWorkRecordMutation();
    const [removeWorkRecord] = useRemoveWorkRecordMutation();

    const company = useContext(CompanyContext);
    checkAlert(company, Authorization.Personnel);
    const employees = useEmployeeList();
    const leaveItemMutationColumns = (params?: any) =>
    {
        return [
            {
                name: "id",
                label: "ID",
                value: params?.row.id,
                type: InputType.hidden,
            },
            {
                name: "employeeId",
                label: "員工",
                type: InputType.autoComplete,
                options: filterByCompany(employees, company).map((employee) => ({
                    value: employee.id,
                    label: `${employee.code} ${employee.name}`
                })),
                value: params?.row.employee.id,
            },
            {
                name: "leaveId",
                label: "假別",
                type: InputType.select,
                options: filterByCompany(leavesData?.leaves, company).map((leave) => ({
                    value: leave.id,
                    label: leave.title
                })),
                value: params?.row.leave.id,
            },
            {name: "startTime", label: "起始時間", type: InputType.datetime, value: params?.row.startTime},
            {name: "endTime", label: "結束時間", type: InputType.datetime, value: params?.row.endTime},
            {name: "restHours", label: "休息時間(小時)", type: InputType.freenumber, value: params?.row.restHours},
            {name: "description", label: "備註", value: params?.row.description},
        ]
    }
    const workRecordMutationColumns = (params?: any) =>
    {
        return [
            {
                name: "id",
                label: "ID",
                value: params?.row.id,
                type: InputType.hidden,
            },
            {
                name: "employeeId",
                label: "員工",
                type: InputType.autoComplete,
                options: filterByCompany(employees, company).map((employee) => ({
                    value: employee.id,
                    label: `${employee.code} ${employee.name}`
                })),
                value: params?.row.employee.id,
            },
            {
                name: "dayType",
                label: "加班歸屬日/兼職出勤日",
                type: InputType.select,
                options: Object.keys(DayTypeMap).map((key) => ({value: key, label: DayTypeMap[key]})),
                value: params?.row.dayType,
            },
            {name: "startTime", label: "起始時間", type: InputType.datetime, value: params?.row.startTime},
            {name: "endTime", label: "結束時間", type: InputType.datetime, value: params?.row.endTime},
            {name: "restHours", label: "休息時間(小時)", type: InputType.freenumber, value: params?.row.restHours},
            {name: "description", label: "原因備註", value: params?.row.description},
        ]
    }

    const onCreate = useCallback(
        async (object, {resetForm}, setOpen) =>
        {
            const createFunction = [
                {fn: createLeave, variables: {leave: {...omit(object, ["id"]), companyId: company!.id}}},
                {fn: createLeaveItem, variables: {leaveItem: {...omit(object, ["id"])}}},
                {fn: createWorkRecord, variables: {workRecord: {...omit(object, ["id"])}}},
            ];
            try {
                await createFunction[tabIndex].fn({
                    variables: createFunction[tabIndex].variables,
                    refetchQueries: ["getLeaves", "getLeaveItems", "getWorkRecords"],
                } as any);
                NotificationPopup.success(`新增完成`);
            } catch (e: any) {
                NotificationPopup.error(
                    `發生問題：${e.message}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        }, [tabIndex, createLeave, createLeaveItem, createWorkRecord, company]);
    const onUpdate = useCallback(async (object, {resetForm}, setOpen) =>
    {
        const updateFunction = [
            {fn: updateLeave, variables: {leave: {...omit(object, ["id"])}, id: object.id}},
            {fn: updateLeaveItem, variables: {leaveItem: {...omit(object, ["id"])}, id: object.id}},
            {fn: updateWorkRecord, variables: {workRecord: {...omit(object, ["id"])}, id: object.id}},
        ];
        try {
            await updateFunction[tabIndex].fn({
                variables: updateFunction[tabIndex].variables,
                refetchQueries: ["getLeaves", "getLeaveItems", "getWorkRecords"],
            } as any);
            NotificationPopup.success(`修改完成`);
        } catch (e: any) {
            NotificationPopup.error(
                `發生問題：${e.message}`
            );
            console.error(e);
        } finally {
            resetForm();
            setOpen(false);
        }
    }, [tabIndex, updateLeave, updateLeaveItem, updateWorkRecord, company])
    const onRemove = useCallback(async (id, itemName) =>
    {
        try {
            if (itemName == "leave") {
                await updateLeave({
                    variables: {id, leave: {disabled: true}},
                    refetchQueries: ["getLeaves"],
                });
            }
            else if (itemName == "leaveItem") {
                await removeLeaveItem({
                    variables: {id},
                    refetchQueries: ["getLeaveItems"],
                });
            }
            else if (itemName == "workRecord") {
                await removeWorkRecord({
                    variables: {id},
                    refetchQueries: ["getWorkRecords"],
                });
            }
            NotificationPopup.success(`刪除完成`);
        } catch (e: any) {
            NotificationPopup.error(
                `發生問題：${e.message}`
            );
            console.error(e);
        }
    }, []);
    const leavesColumns = [
        {field: "id", headerName: "ID", width: 80, hide: true},
        {field: "title", headerName: "假勤名稱", width: 120},
        {field: "limit", headerName: "請假上限", width: 120},
        {
            field: "salaryStandard", headerName: "薪資給薪標準", width: 160,
            valueGetter: params => SalaryStandardMap[(params as any).value]
        },
        {field: "description", headerName: "說明", width: 160},
        {
            field: "action", headerName: "動作", width: 150,
            renderCell: (params) =>
            {
                return <>
                    <UpdatePopupButton
                        title={"修改"}
                        columns={leaveMutationColumns(params)}
                        onSubmit={onUpdate}
                        submitTitle={"儲存"}
                    />&nbsp;
                    <ConfirmButton onConfirm={async () =>
                    {
                        await onRemove(params.row.id, "leave")
                    }}>刪除</ConfirmButton>
                </>
            }
        }
    ]
    const leaveItemsColumns = [
        {field: "id", headerName: "ID", width: 80, hide: true},
        {field: "employee.code", headerName: "員工編號", width: 120, valueGetter: DefaultValueGetter},
        {field: "employee.name", headerName: "姓名", width: 120, valueGetter: DefaultValueGetter},
        {field: "leave.title", headerName: "假勤項目", width: 120, valueGetter: DefaultValueGetter},
        {field: "startTime", headerName: "開始時間", width: 150, valueGetter: DatetimeValueGetter},
        {field: "endTime", headerName: "結束時間", width: 150, valueGetter: DatetimeValueGetter},
        {field: "restHours", headerName: "休息時間(小時)", width: 160},
        {field: "description", headerName: "原因備註", width: 160},
        {
            field: "action", headerName: "動作", width: 150,
            renderCell: (params) =>
            {
                return <>
                    <UpdatePopupButton
                        title={"修改"}
                        columns={leaveItemMutationColumns(params)}
                        onSubmit={onUpdate}
                        submitTitle={"儲存"}
                    />&nbsp;
                    <ConfirmButton onConfirm={async () => {
                        await onRemove(params.row.id, "leaveItem")
                    }}>刪除</ConfirmButton>
                </>
            }
        }
    ]
    const workRecordColumns = [
        {field: "id", headerName: "ID", width: 80, hide: true},
        {field: "employee.code", headerName: "員工編號", width: 120, valueGetter: DefaultValueGetter},
        {field: "employee.name", headerName: "姓名", width: 120, valueGetter: DefaultValueGetter},
        {field: "startTime", headerName: "開始時間", width: 150, valueGetter: DatetimeValueGetter},
        {field: "endTime", headerName: "結束時間", width: 150, valueGetter: DatetimeValueGetter},
        {field: "restHours", headerName: "休息時間(小時)", width: 160},
        {field: "description", headerName: "工作內容備註", width: 200},
        {
            field: "action", headerName: "動作", width: 150,
            renderCell: (params) =>
            {
                return <>
                    <UpdatePopupButton
                        title={"修改"}
                        columns={workRecordMutationColumns(params)}
                        onSubmit={onUpdate}
                        submitTitle={"儲存"}
                    />&nbsp;
                    <ConfirmButton onConfirm={async () => {
                        await onRemove(params.row.id, "workRecord")
                    }}>刪除</ConfirmButton>
                </>
            }
        }
    ]
    return (
        <Page title={"leave page"}>
            <AppBar position="static" color="default">
                <Tabs
                    value={tabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{background: "white"}}
                    onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: number
                    ) =>
                    {
                        setTabIndex(newValue);
                        setSortModel([{field: "id", sort: "asc"}]);
                    }}
                >
                    <Tab label="假勤設定"/>
                    <Tab label="假勤申請"/>
                    <Tab label="加班/兼職出勤"/>
                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                    <DefaultQueryHandler error={leavesError} loading={leavesLoading}>
                        <Box
                            width={"100%"}
                            style={{height: "100vh"}}
                        >

                            <Grid container spacing={2} direction={"row"} alignItems={"center"}>
                                <Grid item>
                                    <UploadFile
                                        uploadTitle={"匯入假勤"}
                                        uploadPath={`/leave/upload?type=leave&companyId=${company?.id}`}
                                        onSuccess={(res) =>
                                        {
                                            NotificationPopup.success(`匯入成功`);
                                            window.location.reload();
                                        }}></UploadFile>
                                </Grid>
                                <Grid item>
                                    <UpdatePopupButton
                                        title={"新增假勤"}
                                        columns={leaveMutationColumns()}
                                        onSubmit={onCreate}
                                        submitTitle={"新增"}
                                    />
                                </Grid>
                                <Grid item>
                                    <DownloadButton
                                        title={"匯出假勤"}
                                        path={`/leave/export?type=leave&companyId=${company?.id}`}
                                        filename={"假勤列表"}
                                        companyId={company?.id}/>
                                </Grid>
                                <Grid item>
                                    <DownloadButton
                                        color={"secondary"}
                                        title={"假勤匯入範例"}
                                        path={`/leave/export?type=leave&companyId=${company?.id}&sample=1`}
                                        filename={"假勤匯入範例"}
                                        companyId={company?.id}/>
                                </Grid>
                            </Grid>
                            <DataGridPro
                                style={{marginTop: 20}}
                                localeText={DataGridLocale}
                                autoHeight={true}
                                loading={leavesLoading}
                                rows={filterByCompany(leavesData?.leaves, company) || []}
                                columns={leavesColumns}
                                sortModel={sortModel}
                                onSortModelChange={(sortModel) =>
                                {
                                    setSortModel(sortModel)
                                }}
                            />
                        </Box>
                    </DefaultQueryHandler>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <DefaultQueryHandler error={leaveItemsError} loading={leaveItemsLoading}>
                        <Box
                            width={"100%"}
                            style={{height: "100vh"}}
                        >
                            <Grid container spacing={2} direction={"row"} alignItems={"center"}>
                                <Grid item>
                                    <UploadFile
                                        uploadTitle={"匯入請假"}
                                        uploadPath={`/leave/upload?type=leaveItem&companyId=${company?.id}`}
                                        onSuccess={(res) =>
                                        {
                                            NotificationPopup.success(`匯入成功`);
                                            window.location.reload();
                                        }}></UploadFile>
                                </Grid>
                                <Grid item>
                                    <UpdatePopupButton
                                        title={"請假申請"}
                                        columns={leaveItemMutationColumns()}
                                        onSubmit={onCreate}
                                        submitTitle={"申請"}
                                    />
                                </Grid>
                                <Grid item>
                                    <DownloadButton
                                        title={"匯出請假"}
                                        path={`/leave/export?type=leaveItem&companyId=${company?.id}`}
                                        filename={"請假列表"}
                                        companyId={company?.id}/>
                                </Grid>
                                <Grid item>
                                    <DownloadButton
                                        color={"secondary"}
                                        title={"請假匯入範例"}
                                        path={`/leave/export?type=leaveItem&companyId=${company?.id}&sample=1`}
                                        filename={"請假匯入範例"}
                                        companyId={company?.id}/>
                                </Grid>
                            </Grid>
                            <DataGridPro
                                style={{marginTop: 20}}
                                localeText={DataGridLocale}
                                autoHeight={true}
                                loading={leavesLoading}
                                rows={filterByCompany(leaveItemsData?.leaveItems, company) || []}
                                columns={leaveItemsColumns}
                                sortModel={sortModel}
                                onSortModelChange={(sortModel) =>
                                {
                                    setSortModel(sortModel);
                                }}
                            />
                        </Box>
                    </DefaultQueryHandler>
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <DefaultQueryHandler error={workRecordsError} loading={workRecordsLoading}>
                        <Box
                            width={"100%"}
                            style={{height: "100vh"}}
                        >
                            <Grid container spacing={2} direction={"row"} alignItems={"center"}>
                                <Grid item>
                                    <UploadFile
                                        uploadTitle={"匯入加班/出勤"}
                                        uploadPath={`/leave/upload?type=workRecord&companyId=${company?.id}`}
                                        onSuccess={(res) =>
                                        {
                                            NotificationPopup.success(`匯入成功`);
                                            window.location.reload();
                                        }}></UploadFile>
                                </Grid>
                                <Grid item>
                                    <UpdatePopupButton
                                        title={"加班/兼職申請"}
                                        columns={workRecordMutationColumns()}
                                        onSubmit={onCreate}
                                        submitTitle={"申請"}
                                    />
                                </Grid>
                                <Grid item>
                                    <DownloadButton
                                        title={"匯出加班/兼職"}
                                        path={`/leave/export?type=workRecord&companyId=${company?.id}`}
                                        filename={"加班/兼職列表"}
                                        companyId={company?.id}/>
                                </Grid>
                                <Grid item>
                                    <DownloadButton
                                        color={"secondary"}
                                        title={"加班/兼職匯入範例"}
                                        path={`/leave/export?type=workRecord&companyId=${company?.id}&sample=1`}
                                        filename={"加班/兼職匯入範例"}
                                        companyId={company?.id}/>
                                </Grid>
                            </Grid>
                            <DataGridPro
                                style={{marginTop: 20}}
                                localeText={DataGridLocale}
                                autoHeight={true}
                                loading={workRecordsLoading}
                                rows={filterByCompany(workRecordsData?.workRecords, company) || []}
                                columns={workRecordColumns}
                                sortModel={sortModel}
                                onSortModelChange={(sortModel) =>
                                {
                                    setSortModel(sortModel);
                                }}
                            />
                        </Box>
                    </DefaultQueryHandler>
                </TabPanel>
            </AppBar>
        </Page>
    )
}