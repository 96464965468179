import SignatureCanvas from 'react-signature-canvas';
import {useRef} from "react";
import {Button} from "@mui/material";
import {saveAs} from 'file-saver';
import {uploadFile} from "./UploadFile";
import NotificationPopup from './NotificationPopup';
import { Grid } from '@mui/material';


const makeBlob = (dataURL)=>
{
    const data = atob( dataURL.substring( "data:image/png;base64,".length ) ),
        asArray = new Uint8Array(data.length);

    for( let i = 0, len = data.length; i < len; ++i ) {
        asArray[i] = data.charCodeAt(i);
    }

    return new Blob( [ asArray.buffer ], {type: "image/png"} );
}

export default ({contractId,onSuccess,onCancel})=>
{
    const sigRef = useRef<any>();
    return <div>
        <div style={{border:'1px solid'}} className={"sigCanvas"}>
            <SignatureCanvas penColor='blue' ref={(ref) => { sigRef.current = ref }}
                             canvasProps={{className: 'sigCanvas'}}
            />
        </div>
        <Grid container justifyContent={"space-between" } style={{flexDirection:"row",maxWidth:"500px", marginTop: 10}}>
            <Grid item>
                <Button variant={"contained"}
                        color={"success"} onClick={()=>
                {
                    sigRef.current.clear();
                }}>清除
                </Button>
                &nbsp;
                <Button variant={"contained"}
                        color={"secondary"} onClick={()=>
                {
                    onCancel()
                }}>取消
                </Button>
            </Grid>
            <Grid item>
                <Button variant={"contained"}
                        color={"primary"}  onClick={async()=>
                {
                    const stuff = sigRef?.current?.toDataURL();
                    const blob = makeBlob(stuff);
                    await uploadFile(`/files/upload?type=signature`,blob,
                        (res)=>{onSuccess(res)});
                }}>
                    送出
                </Button>
            </Grid>
        </Grid>


    </div>
}
