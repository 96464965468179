import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import theme from "../theme/Theme";


export default () =>
{
    const [open, setOpen] = useState(false);
    return (
        <div style={{display: "flex", justifyContent: "end"}}>
            <Dialog open={open} fullWidth={true} maxWidth={"md"}>
                <DialogTitle style={{backgroundColor: theme.palette.primary.dark}}>
                    <Typography variant="h4" align="center">
                        繳費須知
                    </Typography>
                </DialogTitle>
                <DialogContent style={{display: "flex", flexDirection: "column"}}>
                    <br/>
                    系統設定勞報單「狀態為【已完成】」時且需要繳費之專案，將自動開啟繳稅/二代健保之【繳費去】按鈕供您繳費。<br/>
                    若勞報單「狀態非為【已完成】」，而您有急需繳納稅額/二代健保之需求，請點選以下連結，進入財政部及健保署網站自行繳納。<br/>
                    <br/>
                    <div style={{display: "flex"}}>1. <a href={"https://docs.google.com/document/d/1-8uJ-zEPal_4wreJyEmsPFfUeuWelFp6/export?format=pdf"}>所得扣繳稅額填寫說明(PDF)</a></div><br/>
                    <br/>
                    2.繳納連結：<br/>
                    (1.)代扣所得<br/>
                    <a target={"_blank"} href={"https://www.etax.nat.gov.tw/etwmain/etw144w/151"}>所得類別為薪資所得</a><br/>
                    <a target={"_blank"} href={"https://www.etax.nat.gov.tw/etwmain/etw144w/152"}>所得類別為執行業務所得或其他所得</a><br/>
                    (2.)二代健保<br/>
                    <a target={"_blank"} href={"https://eservice.nhi.gov.tw/2nd/"}>補充保險費繳款書列印</a><br/>
                    3.二代健保申報繳納之操作說明<br/>
                    <img src={"/step1.png"} style={{height: "63vh", objectFit: "contain"}}/><br/>
                    <img src={"/step2.png"} style={{height: "63vh", objectFit: "contain"}}/>
                </DialogContent>
                <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                variant={"contained"}
                color={"success"}
                onClick={() => setOpen(true)}
            >
                繳費須知 ---{'>'} 二代健保/代扣所得
            </Button>
        </div>
    )
}